<template>
  <div class="">
    <div class="h-full cust_card p-2 mt-2 w-full md:w-3/4 lg:w-2/4 overflow-visible" style="overflow: visible;">
      <div class="heading-3 text-text1" :class="mobileView ? 'pb-2 heading-5' : 'heading-3'">● Create Campaign:</div>
          <div class="mt-3">
            <div class="">
              <div class="pb-2">
                <TextField
                  :inputId="'planButTitle1231'"
                  :showcharLimit="false"
                  :inputext="sendObj.campaignName"
                  :placholderText="`Campaign Name`"
                  :lableText="'Campaign Name'"
                  :autoFocus="false"
                  :fieldError="errorObj.campNameErr !== ''"
                  @keyPressAction="errorObj.campNameErr = ''"
                  @inputChangeAction="(data) => sendObj.campaignName = data" />
                  <p class="text-error heading-7">{{errorObj.campNameErr}}</p>
              </div>
              <div class="pb-2">
                <TextField
                  :inputId="'planButTitle21423435'"
                  :showcharLimit="false"
                  :inputext="sendObj.optOutMessage"
                  :placholderText="`Opt Out Message`"
                  :lableText="'Opt Out Message'"
                  :autoFocus="false"
                  @inputChangeAction="(data) => sendObj.optOutMessage = data"  />
              </div>
              <div class="mb-3">
                <MultiSelect 
                  :inputext="employeeSearchText" 
                  :inputId="'empslectinpuid'"
                  :lableText="'Assign to Staff User'"
                  :placholderText="`Assign to Staff User`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="false"
                  :showPlus="false"
                  :keyName="'displayText'"
                  :idKey="'userDetailId'"
                  :sectionId="'userDetailId'"
                  :listId="'employeelistidb123'"
                  :items="currentyList"
                  :selectedValues="sendObj.campaignAssignees"
                  :showselectAll="true"
                  :fieldError="errorObj.assigniErr !== ''"
                  @keyPressAction="errorObj.assigniErr = ''"
                  @selectNewForVal="selectEmployee"
                  />
              <!-- <Dropdown
                  :inputext="sendObj.employeeSearchText" 
                  :inputId="'empslectinpuid'"
                  :lableText="'Assign to Staff User'"
                  :placholderText="`Assign to Staff User`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'displayText'"
                  :listId="'employeelistidb123'"
                  :items="currentyList"
                  :fieldError="errorObj.assigniErr !== ''"
                  @keyPressAction="errorObj.assigniErr = ''"
                  @selectNewForVal="selectEmployee"
                /> -->
                <p class="text-error heading-7">{{errorObj.assigniErr}}</p>
              </div>
            </div>
            <div class="mb-3 grid lg:grid-cols-2 gap-4 items-baseline ">
              <div class="relative">
                <TextField :setReadOnly="true" :isPointer="true" :inputId="'234234234'" :showcharLimit="false" :inputext="sendObj.campaignStartDate | StartDateFormate" :placholderText="`Start Date`" :lableText="'Start Date'" :autoFocus="false" @onClickInputAction="openDateSlection=true"/>
                <div class="absolute right-4 top-9 cursor-pointer" v-if="sendObj.campaignStartDate !== ''" @click.stop="sendObj.campaignStartDate = ''"><i class="fas fa-times-circle text-error heading-3"></i></div>
              </div>
              <div>
                <Dropdown
                  :inputext="sendObj.selectedTimezone" 
                  :inputId="'phoneNoVal2'"
                  :lableText="`Select Timezone`"
                  :placholderText="`Select Timezone`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'TimeZoneName'"
                  :listId="'TimeZoneMasterId'"
                  :items="timezoneArr"
                  :fieldError="errorObj.timeZoneErr !== ''"
                  @keyPressAction="errorObj.timeZoneErr = ''"
                  @selectNewForVal="selectTime"
                />
                <p class="text-error heading-7">{{errorObj.timeZoneErr}}</p>
                <div class="flex items-center" v-if="currentTime !== ''">
                  <!-- <div class="text-gray4 heading-6 font-semibold ">Current Date: {{ currentTime }} <span class="heading-7 text-gray4"> ({{this.selectedTimeValues}})</span></div> -->
                  <div class="text-gray4 heading-6 font-semibold ">(Current Date: {{ currentTime }})</div>
                </div>
              </div>
              <div v-if="openDateSlection" class="popup_overlay">
                <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                  <DateTimePickerCustom
                      :minDate="todayDateMin"
                      v-model="sendObj.campaignStartDate"
                      v-if="openDateSlection"
                      :showDate="true"
                      :title="'Start Date'"
                      :showOnlyDate="true"
                      :showStartDate="true"
                      :projectSection="true"
                      @closeDateTimepickerDialog="openDateSlection = false">
                  </DateTimePickerCustom>
                </div>
              </div>
            </div>
            <div class="grid lg:grid-cols-2 gap-4">
            <div class="pb-2">
                <TextField
                  :setReadOnly="true"
                  :isPointer="true"
                  :inputId="'planButTitle1231231'"
                  :showcharLimit="false"
                  :inputext="clockInTime | time12HourFormat"
                  :placholderText="`Start Time`"
                  :lableText="'Start Time'"
                  :autoFocus="false"
                  :showTime="true"
                  @onClickInputAction="clockInModal=true;currentSelect ='start'"/>
              </div>
              <div class="pb-2">
                <TextField
                  :setReadOnly="true"
                  :isPointer="true"
                  :inputId="'planButTitle21423435213'"
                  :showcharLimit="false"
                  :inputext="clockOutTime | time12HourFormat"
                  :placholderText="`End Time`"
                  :lableText="'End Time'"
                  :autoFocus="false"
                  :showTime="true"
                  @onClickInputAction="clockOutModal=true;currentSelect ='end'"  />
              </div>
            </div>
            <p class="text-error heading-6">{{errorObj.timeError}}</p>
            <div class="py-2 items-center">
              <div class="flex items-center flex-wrap pb-2">
                <p class="pl-1 text-text2">Day Selection:</p>
                <p class="text-primary heading-5 pl-1">(
                  <span @click="autoselect('all')" class=""><span class="cursor-pointer">All</span></span>
                  <span class="px-2 text-text2">/</span>
                  <span @click="autoselect('days')" class="cursor-pointer">Working Days</span>
                )
                </p>
              </div>
              <div class="flex flex-wrap">
                <button v-for="(data, index) in dayVal" :key="index" class=" w-auto outline-none rounded-lg hover:border-primary hover:text-text1 transition " :class="`${data.isSelected ? 'border border-primary bg-primary text-white' : 'border border-gray2 bg-white text-gray4'} ${mobileView ? 'heading-7 px-2 p-1 m-1' : ' heading-5 m-1 p-1 px-2 h-10 '}`" @click="minButtonSelectionHandler(data)">
                  <span>{{data.value}}</span>
                </button>
              </div>
              <p class="text-error heading-7 pl-2">{{errorObj.daySelErr}}</p>
            </div>
            <div class="py-2 grid lg:grid-cols-2 items-center gap-4">
              <div>
                <Dropdown
                  :inputext="sendObj.campaignTwilioNumbers[0].displayText" 
                  :inputId="'phoneNoVal'"
                  :lableText="'Select Phone #'"
                  :placholderText="`Select Phone #`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'confName'"
                  :listId="'twilioConfigurationId'"
                  :items="numberArry"
                  :fieldError="errorObj.phoneErr !== ''"
                  @keyPressAction="errorObj.phoneErr = ''"
                  @selectNewForVal="selectNumber"
                />
                <p class="text-error heading-7">{{errorObj.phoneErr}}</p>
              </div>
            </div>
            <div class="py-2 pl-1">
              <div class="heading-5 text-text2 flex items-center" v-if="!isChangeValue">
                <p class="mb-0">You can send maximum {{msgPerMin}} messages per minute</p>
                <span class="text-primary cursor-pointer pl-2" @click="openRunningChange(false)"><i class="fas fa-pen-to-square"></i></span>
              </div>
              <div class="mt-2" v-if="isChangeValue">
                <div class="flex items-center">
                  <div class="text-gray4 heading-5 whitespace-pre-line"><p class="mb-0">Send Max 
                    <input type="number" v-model.number="msgPerMin" :class="errorObj.sendMaxErr ? 'border border-error text-error' : 'border border-gray4'" class="page-number-input outline-none ring-0  text-center"/> messages per minute</p>
                  </div>
                  <span class="flex items-center pl-4">
                    <div class=" text-white cursor-pointer bg-error px-1.5 rounded-full" @click.stop="openRunningChange(false)">
                      <i class="fas fa-times"></i>
                    </div>
                    <div class=" text-white cursor-pointer bg-primary px-1.5 rounded-full ml-2" @click.stop="openRunningChange(true)">
                      <i class="fas fa-check"></i>
                    </div>
                  </span>
                </div>
              </div>
              <p class="heading-7 text-error">{{ errorObj.sendMaxErr }}</p>
            </div>
            <div class="py-2">
              <div class="justify-end flex mt-2">
                <Button class="ml-4" @buttonAction="openpopup()" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'View/Edit Text Drip'"/>
              </div>
              <p class="text-error heading-6 pl-1 text-right justify-end" v-if="dripMsgErr !== ''">{{dripMsgErr}}</p>
              <p class="text-error heading-6 pl-1 text-right justify-end pt-1" ref="myElement" v-if="copedFromData !== null">Text Drip data copied from '{{copedFromData.campaignName}}'</p>
            </div>
            <div v-for="(dataA, index) in sendObj.campaignContactList" :key="index" class="">
              <div class="flex items-center">
                <div class="pb-2 gap-4 " :class="mobileView ? 'w-full' : 'w-2/4'">
                <Dropdown
                    :inputext="dataA.selectListValue" 
                    :inputId="'currencyData'"
                    :lableText="'Select List'"
                    :placholderText="`Select List`"
                    :autoFocus="false"
                    :showArrow="true"
                    :setReadOnly="false"
                    :searchCallApi="true"
                    :showPlus="false"
                    :keyName="'displayText'"
                    :listId="'userDetailId'"
                    :items="selectListArray"
                    @focus="currentIndexOfWorkExp = index"
                    :fieldError="dataA.listErr !== ''"
                    @keyPressAction="dataA.listErr = ''"
                    @selectNewForVal="selectNewCustomer"
                  />
                  <p class="text-error heading-7">{{dataA.listErr}}</p>
                </div>
                <div>
                  <div class="flex justify-end pl-4">
                    <span class=" rounded-full h-8 w-8 bg-error mr-1 cursor-pointer flex items-center text-center justify-center" v-if="sendObj.campaignContactList.length > 1" @click="removeWorkDetails(index)">
                      <i class="fas fa-minus text-white heading-3"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-error heading-7 pl-1">Note: If the uploaded list contains same numbers, they will be removed.</p>
            <div class="text-primary p-2" >
              <span class="cursor-pointer" @click.stop="addWorkDetails()">+ Add More List</span>
            </div>
            <p class="text-error heading-7 pl-1">Any Cell number found in current running campaign will be ignored.</p>
            <div class="flex items-center py-2 pt-4">
              <label class="cu_switch cursor-pointer mr-3" :class="!isSkipContacts ? 'ml-2' : ''">
                <input type="checkbox" v-model="isSkipContacts" @click="changeButtpn2()">
                <span class="cu_slider cu_round"></span>
              </label>
              <p class="text-gray3 pl-1">{{isSkipContacts ? 'Skip Contacts that has ever replied back' : `Don't Skip Contacts that has ever replied back`}}</p>
            </div>
            <div class="flex items-center pt-2">
              <label class="cu_switch cursor-pointer mr-3" :class="!sendObj.isActive ? 'ml-2' : ''">
                <input type="checkbox" v-model="sendObj.isActive" @click="changeButtpn()">
                <span class="cu_slider cu_round"></span>
              </label>
              <p class="text-gray3 pl-1">{{sendObj.isActive ? 'Campaign is active' : 'Campaign is Inactive'}}</p>
            </div>
            </div>
        <div class="flex justify-between p-4 sticky bottom-0 bg-white card rounded-lg">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closeNew()"/>
            <Button :disabled="isChangeValue" v-if="sendObj.campaignStartDate === '' || sendObj.campaignStartDate === null" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save & Start Campaign Now'" @buttonAction="crateJob(false)"/>
            <Button :disabled="isChangeValue" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="crateJob(true)"/>
        </div>
    </div>
    <div class="popup_overlay px-4" v-if="textDripPopup">
      <div class="custom_dialog rounded-xl" style="width: 800px; max-height: 92%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Text Drip
              </div>
            </div>
            <div ref="container"  class="p-4 overflow-auto" style="max-height: 75vh">
              <div class="heading-4 text-text2">{{ sendObj.campaignName }}</div>
              <div  v-for="(dataB, index) in sendObj.campaignDripMessages" :key="index" class="py-2 relative">
                <div class="card rounded-lg bg-white border border-gray3 p-2 my-2">
                <div class="flex items-center justify-between">
                  <div class="text-text2 heading-4 font-semibold pl-0 pt-1 pb-2">Message: {{index + 1}}</div>
                  <div class="" v-if="sendObj.campaignDripMessages.length > 1 && index > 0" ><span @click="removeDripText(index)"><i class="fas fa-trash-alt text-error cursor-pointer"></i></span></div>
                </div>
                <div class="pb-2">
                  <p class="text-text2 heading-6 mb-2" v-if="index !== 0">Please select time to wait before sending below message</p>
                  <p class="text-error heading-5 pl-2 -mt-2">{{dataB.timeErr}}</p>
                  <div class="p-2 pt-0 pl-4" v-if="index !== 0">
                    <TimePicker :title="'Start Time'" v-model="dataB.startTime"/>
                  </div>
                  <p class="label_css flex"><span>Campaign Text Drip</span> </p>
                  <textarea :ref="'messageInput' + index" :id="'messageInputBox'+index"
                    maxlength="1000"
                    cols="50"
                    rows="3"
                    placeholder="Campaign Text Drip"
                    @keypress="dataB.nameErr = '',isErrorHere=false"
                    class="cust_text_field py-4  px-2 w-full  m-px flex text-text1 bg-white rounded items-center"
                    :class="`${dataB.nameErr !== '' ? 'ring-1 ring-error' : 'border border-gray2  flex text-text1 focus:border-primary focus:ring-1'}`"
                    autocomplete="off"
                    v-model="dataB.textToSend"
                  ></textarea>
                    <!-- <TextAreaInput
                        :inputId="'campdrip'+ index"
                        :inputext="dataB.textToSend"
                        :placholderText="`Campaign Text Drip`"
                        :lableText="''"
                        :inputType="''"
                        :autoFocus="false"
                        :textMaxlength="1000"
                        :showcharLimit="true"
                        :cols="50"
                        :rows="3"
                        :fieldError="dataB.nameErr !== ''"
                        @keyPressAction="dataB.nameErr = ''"
                        :setReadOnly="false"
                        @inputChangeAction="(data) => dataB.textToSend = data"  /> -->
                        <p class="text-error heading-7">{{dataB.nameErr}}</p>
                  </div>
                  <div class="mb-1">
                    <div class="label_css">Please click tags shown below to add in Campaign Text Drip</div>
                    <div class="mb-1  grid lg:grid-cols-3 md:grid-cols-2">
                        <div v-for="(data, II) in prefrenceTags" :key="II" class="heading-6  ml-2 text-text2">
                          <span><span class="text-primary cursor-pointer hover:border-gray4 border p-0.5 border-transparent transition rounded-md" @click="addTags(data.fieldSlug, index)">• {{ data.displayName }}</span></span>
                        </div>  
                    </div>
                  </div>
                  <!-- <div class="mb-1">
                    <div class="label_css">Please click tags shown below to add in Campaign Text Drip</div>
                      <div v-for="(data, II) in prefrenceTags" :key="II" class="heading-6  ml-2 text-text2">
                        <span><span class="text-primary cursor-pointer" @click="addTags(data.fieldSlug, index)">• {{ data.displayName }}</span></span>
                      </div>  
                  </div> -->
                  <div class="py-2" v-if="index === 0">
                    <p class="mb-0 flex items-center">
                      <span class="text-gray4 heading-5 "><p class="mb-0" >Opt Out Message:</p></span>
                      <span class="text-text2 heading-5 font-semibold whitespace-pre-line pl-2" v-if="sendObj.optOutMessage !== ''" >{{sendObj.optOutMessage}}</span>
                      <span class="text-text2 heading-5 font-semibold whitespace-pre-line pl-2" v-if="sendObj.optOutMessage === ''" >--Not Entered--</span>
                    </p>
                    <!-- <TextField
                      :disabled="true"
                      :inputId="'test123'+ index"
                      :showcharLimit="false"
                      :inputext="sendObj.optOutMessage"
                      :placholderText="`Opt Out Message`"
                      :lableText="'Opt Out Message'"
                      :autoFocus="false"
                      @inputChangeAction="(data) => sendObj.optOutMessage = data"  /> -->
                  </div>
                  <div class="text-primary p-2 text-right" >
                    <span @click.stop="addTextDripValue(index)" v-if="index === sendObj.campaignDripMessages.length - 1 && !isAddingNewTextDrip" class="cursor-pointer ">+ Add new Text Drip</span>
                  </div>
                </div>
                <!-- <div class="text-right absolute top-12 right-5"><i class="fas fa-trash-alt text-error cursor-pointer"></i></div> -->
              </div>
            </div>
            <div class="divider"></div>
            <div class="text-right flex justify-end text-error pr-2 -mb-2 mt-1" v-if="isErrorHere">Please look for errors on the screen to resolve</div>
            <div class="sticky bottom-0 flex justify-end p-3">
              <div class="text-rigth flex gap-4">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="clearTextDrip()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveApi()"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="clockInModal">
      <div class="custom_dialog rounded-xl p-4">
        <timepickerCust :mainTitle="`Start Time:`" :showDiscard="true" :isCustomPicker="false"
          :value="clockInTime"
          @click:minute="$refs.dialog.save(clockInTime)"
          format="24hr">
        </timepickerCust>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="clockOutModal">
      <div class="custom_dialog rounded-xl p-4">
        <timepickerCust :mainTitle="`End Time:`" :showDiscard="true" :isCustomPicker="false"
        :value="clockOutTime"
        @click:minute="$refs.dialog1.save(clockOutTime)"
        format="24hr">
      </timepickerCust>
      </div>
    </div>
    <div v-if="istart" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="font-bold text-center">
          <p class="heading-3 text-error">Alert</p>
        </div>
        <div class="divider my-2"></div>
        <div  class="mb-4">
          <p class="heading-5 text-error mb-4">Campaign will start in next 2-3 minutes</p>
          <p class="heading-5 text-error mb-4" v-if="showErrorAlert"> Start Date & Time is conflicting with days selected, please review</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel & Go back'" @buttonAction="isWorkPnding(false)"/>
          <Button v-if="!showErrorAlert" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'I Understand'" @buttonAction="isWorkPnding(true)"/>
        </div>
      </div>
    </div>
   </div>
</template>
<script>
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import moment from 'moment'
import timepickerCust from '@/View/components/timepicker/timepicker.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import TimePicker from '@/View/components/timePicker.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  name: "customer",
  components: {
    DateTimePickerCustom,
    MultiSelect,
    Button,
    timepickerCust,
    TimePicker,
    // TextAreaInput,
    TextField,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      isErrorHere: false,
      showErrorAlert: false,
      perMinApi: 0,
      isChangeValue: false,
      openDateSlection: false,
      employeeSearchText: '',
      selectListArray: [],
      dayVal: [
        {id: 0, value: 'Sunday', isSelected: false}, 
        {id: 1, value: 'Monday', isSelected: false}, 
        {id: 2, value: 'Tuesday', isSelected: false}, 
        {id: 3, value: 'Wednesday', isSelected: false}, 
        {id: 4, value: 'Thursday', isSelected: false}, 
        {id: 5, value: 'Friday', isSelected: false}, 
        {id: 6, value: 'Saturday', isSelected: false}
      ],
      timeZones: ['America/Creston', 'America/Anchorage', 'Pacific/Honolulu', 'America/New_York', 'America/Los_Angeles'],
      isAddingNewTextDrip: false,
      prefrenceTags: null,
      numberArry: [],
      currentTime: '',
      timezoneArr: [],
      currentSelect: '',
      clockOutTime: '17:00:00',
      clockInTime: '08:00:00',
      clockInModal: false,
      clockOutModal: false,
      startTime: '',
      endTime: '',
      dripMsgErr: '',
      textDripPopup: false,
      currencyName: '',
      mobileView: false,
      planDetailObj: null,
      currentIndexOfWorkExp: 0,
      selectedTimeValues: '',
      featuresList: [],
      currentyList: [],
      selectedEmployee: [],
      selectedTeam: [],
      todayDateMin: '',
      copedFromData: null,
      interval: 0,
      errorObj: {
        sendMaxErr: '',
        campNameErr: '',
        assigniErr: '',
        timeError: '',
        phoneErr: '',
        timeZoneErr: '',
        daySelErr: '',
      },
      msgPerMin: 0,
      istart: false,
      isCampaignStartNow: false,
      isSkipContacts: true,
      sendObj: {
        maxMessagePerMin: 0,
        campaignStartDate: '',
        campaignName: '',
        optOutMessage: '',
        timeZoneMasterId: 0,
        selectedTimezone: '',
        startTime: null,
        endTime: null,
        startTimeFormat: null,
        endTimeFormat: null,
        employeeSearchText: '',
        assigneeUserId: 0,
        campaignAssignees: [],
        days: [],
        isActive: true,
        campaignContactList: [
          {
            listErr: '',
            isAdd: true,
            contactListMasterId: 0,
            selectListValue: '',
          }
        ],
        campaignDripMessages: [
          {
            nameErr: '',
            timeErr: '',
            campaignTextDripId: 0,
            textToSend: '',
            optoutMsg: '',
            startTime: '0:0:00',
            endTime: '',
            prevWaitDurationInSec: 0
          }
        ],
        campaignTwilioNumbers: [
          {
            displayText: '',
            campaignTwilioNumberMappingId: 0,
            twilioConfigurationId: 0
          }
        ]
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  computed: {
  },
  watch: {
    msgPerMin: {
      handler() {
        console.log('Watch On:-msgPerMin', this.msgPerMin, this.perMinApi);
        if (this.msgPerMin > this.perMinApi) {
          this.errorObj.sendMaxErr = `You can send maximum ${this.perMinApi} messages per minute`;
        } else if (this.msgPerMin > 55 && this.perMinApi > 55) {
          this.errorObj.sendMaxErr = "You can send maximum 55 messages per minute";
        } else if (this.msgPerMin > 55) {
          this.errorObj.sendMaxErr = "You can send maximum 55 messages per minute";
        } else {
          this.errorObj.sendMaxErr = "";
        }
      },
      deep: true
    },
    sendObj: {
      handler () {
        console.log('sendObj', this.sendObj)
        for (let index = 0; index < this.sendObj.campaignDripMessages.length; index++) {
          if (this.sendObj.campaignDripMessages[index].startTime !== '' && this.sendObj.campaignDripMessages[index].startTime !== '0:0:00' && this.sendObj.campaignDripMessages[index].startTime !== '0:00:00') {
            this.sendObj.campaignDripMessages[index].timeErr = ''
            this.isErrorHere = false
          }
        }
      },
      deep: true
    }
  },
  beforeMount() {
  },
  mounted() {
    this.todayDateMin = moment(new Date()).format('YYYY-MM-DD')
    let todaysDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString()
    this.sendObj.startTime = new Date(todaysDate + ' ' + this.clockInTime)
    this.sendObj.endTime = new Date(todaysDate + ' ' + this.clockOutTime)
    if (this.$route.query.campaignMasterId !== undefined) {
      this.getDetailData()
    }
    document.title = 'Add Campaign'
    this.getMessageData()
    this.$root.$on('closeDialogFromTime', (data, response) => {
      console.log('data', data)
      console.log('response', response)
      if (data) {
        if (this.currentSelect === 'start') {
          this.clockInTime = response
        } else if (this.currentSelect === 'end') {
          this.clockOutTime = response
        }
      }
      this.locateTime()
      this.clockInModal = false 
      this.clockOutModal = false 
    })
    if (this.selectedTeam.length > 0) {
      this.selectedEmployee = []
      this.selectedTeam.map(el => {
        this.selectedEmployee.push({...el, displayText: el.firstName + ' ' + el.lastName})
      })  
    }
    this.getTwilioData()
    this.getPrefrencesListing()
    this.getTimezoneList()
    this.getListListing()
    this.getUserStaffList()
    this.resizeWindowHandler();
    console.log("route", this.$route.query);
    const myElement = this.$refs.myElement;
    console.log('myElement', myElement)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    isWorkPnding (data) {
      if (data) {
        this.sendObj.endTimeFormat = this.time12HourFormat(this.clockOutTime)
        this.sendObj.startTimeFormat = this.time12HourFormat(this.clockInTime)
        this.dripMsgErr = ''
        this.sendObj.startTime = this.sendObj.startTime.toUTCString()
        this.sendObj.endTime = this.sendObj.endTime.toUTCString()
        this.sendObj.days = JSON.stringify(this.sendObj.days)
        console.log('SEND OBJECT THIS IS NEW VALUE---------->', this.sendObj)
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        ADMINAPI.SaveNewCampaign(
          this.sendObj,
          this.isCampaignStartNow,
          this.isSkipContacts,
          response => {
            this.$router.push({name: 'campaignDetail', params: {campaignMasterId: response.Data}})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
        this.istart = false
      } else {
        this.istart = false
      }
    },
    getMessageData () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.GetMessageLimit(
        response => {
          this.perMinApi = response.Data.perMin
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    openRunningChange (data) {
      if (!data) {
        this.msgPerMin = this.sendObj.maxMessagePerMin
        this.isChangeValue = !this.isChangeValue
        this.errorObj.sendMaxErr = ''
      } else {
        if (this.msgPerMin === 0) {
          this.errorObj.sendMaxErr = 'Please add value ​​greater than 0'
        } else if (this.msgPerMin === '') {
          this.errorObj.sendMaxErr = 'Please add value'
        }
        if (this.errorObj.sendMaxErr === '') {
          console.log('this.msgPerMin', this.msgPerMin)
          this.sendObj.maxMessagePerMin = this.msgPerMin
          this.isChangeValue = !this.isChangeValue
        }
      }
    },
    addTags (addValue, index) {
      let el = document.getElementById(`messageInputBox${index}`)
      let strval = el.value
      let start = el.selectionStart
      let end = el.selectionEnd
      this.sendObj.campaignDripMessages[index].nameErr = ''
      this.isErrorHere = false
      this.sendObj.campaignDripMessages[index].textToSend = strval.slice(0, start) + addValue + strval.slice(end)
      let cursorPos = start + addValue.length
      setTimeout(() => {
        el.focus()
        el.setSelectionRange(cursorPos, cursorPos)
      }, 0)
    },
    autoselect (data) {
      if (data === 'all') {
        this.sendObj.days = []
        this.dayVal.forEach(ele => {
          this.sendObj.days.push(ele.id)
          ele.isSelected = true
        });
      } else if (data === 'days') {
        this.sendObj.days = []
        this.dayVal.forEach(ele => {
          if (ele.value !== 'Sunday' && ele.value !== 'Saturday') {
            ele.isSelected = true
            this.sendObj.days.push(ele.id)
          } else {
            ele.isSelected = false
          }
        });
      }
      this.errorObj.daySelErr = ''
    },
    minButtonSelectionHandler (data) {
      data.isSelected = !data.isSelected
      this.sendObj.days = []
      this.errorObj.daySelErr = ''
      console.log('data', data)
      this.dayVal.forEach(ele => {
        if (ele.isSelected) {
          this.sendObj.days.push(ele.id)
        }
      });
    },
    locateTime () {
      console.log('this.clockInTime', this.clockInTime)
      console.log('this.clockOutTime', this.clockOutTime)
      if (this.clockInTime !== '' && this.clockOutTime !== '') {
        this.errorObj.timeError = ''
        let todaysDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString()
        this.sendObj.startTime = new Date(todaysDate + ' ' + this.clockInTime)
        this.sendObj.endTime = new Date(todaysDate + ' ' + this.clockOutTime)
        console.log('x', this.sendObj.startTime)
        console.log('y', this.sendObj.endTime)
        if (this.sendObj.startTime > this.sendObj.endTime) {
          this.errorObj.timeError = 'End Time time is greater than start time'
        } else {
          this.errorObj.timeError = ''
        }
      }
    },
    clearTextDrip () {
      this.textDripPopup = false
      this.dripMsgErr = ''
      // this.sendObj.campaignDripMessages = []
      // this.sendObj.campaignDripMessages.push({
      //   nameErr: '',
      //   timeErr: '',
      //   campaignTextDripId: 0,
      //   textToSend: '',
      //   optoutMsg: '',
      //   startTime: '1:00',
      //   endTime: '',
      //   prevWaitDurationInSec: 0
      // })
    },
    saveApi () {
      let isValid = true
      for (let index = 0; index < this.sendObj.campaignDripMessages.length; index++) {
        if (this.sendObj.campaignDripMessages[index].textToSend === '') {
          this.sendObj.campaignDripMessages[index].nameErr = 'Campaign text drip message is required'
          isValid = false
          // this.textDripPopup = true
        }
        if (index > 0) {
          if (this.sendObj.campaignDripMessages[index].startTime === '' || this.sendObj.campaignDripMessages[index].startTime === '0:0:00' || this.sendObj.campaignDripMessages[index].startTime === '0:00:00') {
            this.sendObj.campaignDripMessages[index].timeErr = 'Please select time to wait'
            isValid = false
            this.isErrorHere = true
            // this.textDripPopup = true
          }
        }
      }
      if (!isValid) {
        this.isErrorHere = true
      } else {
        this.isErrorHere = false
      }
      if (isValid) {
        this.dripMsgErr = ''
        this.textDripPopup = false
        for (let index = 0; index < this.sendObj.campaignDripMessages.length; index++) {
          let array = this.sendObj.campaignDripMessages[index].startTime.split(":")
          console.log('array', array)
          this.sendObj.campaignDripMessages[index].days = parseInt(array[0])
          this.sendObj.campaignDripMessages[index].hour = parseInt(array[1])
          this.sendObj.campaignDripMessages[index].minutes = parseInt(array[2])
          let DaysSec = this.sendObj.campaignDripMessages[index].days * 86400
          let HoursSec = this.sendObj.campaignDripMessages[index].hour * 3600
          let minSec = this.sendObj.campaignDripMessages[index].minutes * 60
          this.sendObj.campaignDripMessages[index].prevWaitDurationInSec = DaysSec + HoursSec + minSec
        }
      }
      console.log('this.sendObj', this.sendObj)
    },
    closeNew () {
      this.$router.push({name: 'Campaigns'})
    },
    crateJob (value) {
      let isValid = true
      if (this.sendObj.campaignName === '') {
        this.errorObj.campNameErr = 'Campaign name is required'
        isValid = false
      }
      if (this.sendObj.campaignAssignees.length === 0) {
        this.errorObj.assigniErr = 'Please assign at least 1 user'
        isValid = false
      }
      if (this.sendObj.campaignTwilioNumbers[0].twilioConfigurationId === 0) {
        this.errorObj.phoneErr = 'Phone # is required'
        isValid = false
      }
      if (this.sendObj.timeZoneMasterId === 0) {
        this.errorObj.timeZoneErr = 'Time Zone value is required'
        isValid = false
      }
      if ((this.sendObj.startTime === null || this.sendObj.startTime === '') || (this.sendObj.endTime === null || this.sendObj.endTime === '')) {
        this.errorObj.timeError = 'Please select a Date'
        isValid = false
      }
      if (this.errorObj.timeError !== '') {
        isValid = false
      }
      if (this.sendObj.maxMessagePerMin === 0) {
        this.errorObj.sendMaxErr = 'Please add value ​​greater than 0'
        isValid = false
      }
      if (this.errorObj.sendMaxErr !== '') {
        isValid = false
      }
      if (this.sendObj.days.length === 0) {
        this.errorObj.daySelErr = 'Please select at least 1 day'
        isValid = false
      }
      for (let index = 0; index < this.sendObj.campaignContactList.length; index++) {
        if (this.sendObj.campaignContactList[index].contactListMasterId === 0) {
          this.sendObj.campaignContactList[index].listErr = 'Please select List'
          isValid = false
        }
      }
      for (let index = 0; index < this.sendObj.campaignDripMessages.length; index++) {
        if (this.sendObj.campaignDripMessages[index].textToSend === '') {
          this.sendObj.campaignDripMessages[index].nameErr = 'Campaign text drip message is required'
          isValid = false
          this.dripMsgErr = 'Please fill required text drip data'
          // this.textDripPopup = true
        }
        if (index > 0) {
          if (this.sendObj.campaignDripMessages[index].startTime === '' || this.sendObj.campaignDripMessages[index].startTime === '0:0:00' || this.sendObj.campaignDripMessages[index].startTime === '00:00') {
            this.sendObj.campaignDripMessages[index].timeErr = 'Please select time  to wait before sending above message'
            isValid = false
            this.isErrorHere = true
            this.dripMsgErr = 'Please fill required text drip data'
            // this.textDripPopup = true
          }
        }
      }
      if (this.sendObj.campaignStartDate === '' || this.sendObj.campaignStartDate === null) {
        const selectedDate = new Date(this.todayDateMin);
        const selectedDayIndex = selectedDate.getDay();
        if (!this.dayVal[selectedDayIndex].isSelected) {
          this.showErrorAlert = true; // If isSelected is false, set showErrorAlert to true
        } else {
          this.showErrorAlert = false; // If isSelected is true, set showErrorAlert to false
        }
      }
      if (!isValid) {
        this.isErrorHere = true
      } else {
        this.isErrorHere = false
      }
      if (isValid) {
        if (value) {
          this.sendObj.endTimeFormat = this.time12HourFormat(this.clockOutTime)
          this.sendObj.startTimeFormat = this.time12HourFormat(this.clockInTime)
          this.dripMsgErr = ''
          this.sendObj.startTime = this.sendObj.startTime.toUTCString()
          this.sendObj.endTime = this.sendObj.endTime.toUTCString()
          this.sendObj.days = JSON.stringify(this.sendObj.days)
          console.log('SEND OBJECT THIS IS NEW VALUE---------->', this.sendObj, value)
          this.isCampaignStartNow = false
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.SaveNewCampaign(
            this.sendObj,
            this.isCampaignStartNow,
            this.isSkipContacts,
            response => {
              this.$router.push({name: 'campaignDetail', params: {campaignMasterId: response.Data}})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        } else {
          this.isCampaignStartNow = true
          this.istart = true
        }
      }
    },
    time12HourFormat (value) {
      if (value) {
        return moment(String(value), 'hh:mm').format('h:mm A')
      }
    },
    getDetailData () {
      ADMINAPI.GetCampaignDetail(
        parseInt(this.$route.query.campaignMasterId),
        response => {
          this.sendObj.campaignDripMessages = []
          this.copedFromData = response.Data
          if (response.Data.campaignDripMessages !== null) {
            for (let index = 0; index < response.Data.campaignDripMessages.length; index++) {
              let Ele = response.Data.campaignDripMessages[index]
              let prevWaitDurationInSec = 0
              if (index > 0) {
                prevWaitDurationInSec = response.Data.campaignDripMessages[index-1].prevWaitDurationInSec
              }
              let Obj = {
                nameErr: '',
                timeErr: '',
                campaignTextDripId: Ele.campaignTextDripId,
                textToSend: Ele.textToSend,
                optoutMsg: this.sendObj.optOutMessage,
                startTime: this.timeConvertor(prevWaitDurationInSec),
                endTime: '',
                prevWaitDurationInSec: Ele.prevWaitDurationInSec,
                isDripSent: Ele.isDripSent
              }
              this.sendObj.campaignDripMessages.push(Obj)
            }
          }
          setTimeout(() => {
            this.$refs.myElement.innerHTML = "";
          }, 5000);
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    timeConvertor(seconds) {
        var days = Math.floor(seconds / (3600 * 24));
        var hours = Math.floor((seconds % (3600 * 24)) / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);

        var formattedTime = '';
        if (days > 0) {
          formattedTime += days.toString().padStart(2, '0') + ':';
        } else {
          formattedTime += '0:';
        }
        formattedTime += hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        return formattedTime;
      },
    openpopup () {
      this.textDripPopup = true
    },
    selectEmployee (emplist) {
      this.errorObj.assigniErr = ''
      this.sendObj.campaignAssignees = []
      let tempEmpList = []
      emplist.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
          assigneeUserId: data.userDetailId,
          displayText: data.displayText,
          assigneeFirstName: data.firstName,
          assigneeLastName: data.lastName,
        }
        tempEmpList.push(obj)
        this.sendObj.campaignAssignees.push(obj)
      })
    },
    selectEmployee2 (data) {
      console.log('data', data)
      // if (data !== null) {
      //   this.errorObj.assigniErr = ''
      //   this.sendObj.employeeSearchText = data.displayText
      //   this.sendObj.assigneeUserId = data.userDetailId
      // } else {
      //   this.sendObj.employeeSearchText = ''
      //   this.sendObj.assigneeUserId = 0
      // }
    },
    changeButtpn2 () {
      this.isSkipContacts = !this.isSkipContacts
    },
    changeButtpn () {
      this.sendObj.isActive = !this.sendObj.isActive
    },
    selectNumber (data) {
      console.log('312321312313', data)
      if (data !== null) {
        this.errorObj.phoneErr = ''
        this.sendObj.campaignTwilioNumbers[0].displayText = data.confName
        this.sendObj.campaignTwilioNumbers[0].twilioConfigurationId = data.twilioConfigurationId
        this.sendObj.campaignTwilioNumbers[0].campaignTwilioNumberMappingId = 0
      } else {
        this.sendObj.campaignTwilioNumbers[0].displayText = ''
        this.sendObj.campaignTwilioNumbers[0].twilioConfigurationId = 0
        this.sendObj.campaignTwilioNumbers[0].campaignTwilioNumberMappingId = 0
      }
      
    },
    updateTime(data) {
      const now = new Date();
      const selectedTimeZoneIndex = this.timeZones.indexOf(data.timeZoneValue);
      const timeZone = selectedTimeZoneIndex !== -1 ? this.timeZones[selectedTimeZoneIndex] : this.timeZones[0]; // Default to first time zone if selectedTimeZone not found

      const options = { 
          year: 'numeric', 
          month: 'numeric', 
          day: 'numeric',
          hour: 'numeric', 
          minute: 'numeric', 
          second: 'numeric', 
          timeZone: timeZone 
      };

      const currentTime = now.toLocaleString('en-US', options);
      this.currentTime = currentTime;
    },
    selectTime (data) {
      console.log('312321312313', data)
      if (data !== null) {
        this.selectedTimeValues = data.timeZoneValue
        this.updateTime(data)
        clearInterval(this.interval); // Clearing interval if it exists
        this.interval = setInterval(() => {
            this.updateTime(data); // Update time every second
        }, 1000);
        this.errorObj.timeZoneErr = ''
        this.sendObj.selectedTimezone = data.TimeZoneName
        this.sendObj.timeZoneMasterId = data.TimeZoneMasterId
    } else {
        clearInterval(this.interval); // Clearing interval if data is null
        this.sendObj.selectedTimezone = ''
        this.sendObj.timeZoneMasterId = 0
        this.selectedTimeValues = ''
        this.currentTime = ''
    }
    },
    selectNewCustomer (data) {
      console.log('312321312313', data)
      if (data !== null) {
        this.sendObj.campaignContactList[this.currentIndexOfWorkExp].listErr = ''
        this.sendObj.campaignContactList[this.currentIndexOfWorkExp].selectListValue = data.displayText
        this.sendObj.campaignContactList[this.currentIndexOfWorkExp].contactListMasterId = data.contactListMasterId
        data.isDisable = true
        setTimeout(() => {
          this.arrayChanges()
        }, 200);
      } else {
        setTimeout(() => {
          this.arrayChanges()
        }, 200);
        this.sendObj.campaignContactList[this.currentIndexOfWorkExp].contactListMasterId = 0
        this.sendObj.campaignContactList[this.currentIndexOfWorkExp].selectListValue = ''
      }
    },
    arrayChanges () {
      for (let II = 0; II < this.selectListArray.length; II++) {
        if (this.selectListArray[II].isDisable) {
          console.log('YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY', this.selectListArray[II])
          for (let index = 0; index < this.sendObj.campaignContactList.length; index++) {
            if (this.sendObj.campaignContactList[index].selectListValue.trim().toLowerCase() === this.selectListArray[II].displayText.trim().toLowerCase()) {
              this.selectListArray[II].isDisable = true
              break
            } else {
              this.selectListArray[II].isDisable = false
            }
          }
        }
      }
    },
    getUserStaffList () {
      console.log('this.routing', this.orgId)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetStaffList(
        0,
        40,
        '',
        '',
        '',
        true,
        response => {
          let temp = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let index = 0; index < temp.length; index++) {
            temp[index].displayText = temp[index].firstName + ' ' + temp[index].lastName
          }
          this.currentyList = temp
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getListListing () {
      console.log('this.routing', this.orgId)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetListList(
        0,
        100,
        '',
        '',
        '',
        response => {
          let temp = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let index = 0; index < temp.length; index++) {
            temp[index].displayText = temp[index].listName
          }
          this.selectListArray = temp
          // for (let index = 0; index < this.sendObj.campaignContactList.length; index++) {
          //   this.sendObj.campaignContactList[index].selectListArray = temp
          // }
          console.log('this.sendObjsadd.', this.sendObj.campaignContactList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getTimezoneList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetTimeZones(
        response => {
          console.log('response.response.', response)
          this.timezoneArr = response.Data !== null ? response.Data : []
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getPrefrencesListing () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetPrefrenceTag(
        response => {
          this.prefrenceTags = response.Data !== null ? response.Data : []
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getTwilioData () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.TwilioConfigurationList(
        0,
        50,
        '',
        '',
        '',
        'active',
        response => {
          console.log('response.response.', response)
          let numberArry = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < numberArry.length; index++) {
            numberArry[index].confName = numberArry[index].phoneNumber + ' (' + numberArry[index].configLabel + ')'
          }
          this.numberArry = numberArry
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    removeDripText (index) {
      if (this.sendObj.campaignDripMessages.length !== 1) {
        this.sendObj.campaignDripMessages.splice(index, 1)
      }
    },
    addTextDripValue () {
      this.isAddingNewTextDrip = true
      this.sendObj.campaignDripMessages.push({
        nameErr: '',
        timeErr: '',
        campaignTextDripId: 0,
        textToSend: '',
        optoutMsg: '',
        startTime: '0:0:00',
        endTime: '',
        prevWaitDurationInSec: 0
      })
      this.$nextTick(() => {
        const container = this.$refs.container;
        console.log('container', container)
        container.scrollTop = container.scrollHeight;
      });
      this.$nextTick(() => {
        const lastIndex = this.sendObj.campaignDripMessages.length - 1;
        const lastTextarea = this.$refs['messageInput' + lastIndex][0];
        if (lastTextarea) {
          lastTextarea.focus();
        }
      });
      setTimeout(() => {
        this.isAddingNewTextDrip = false;
      }, 1000);
    },
    removeWorkDetails (index) {
      if (this.sendObj.campaignContactList.length !== 1) {
        this.sendObj.campaignContactList.splice(index, 1)
      }
    },
    addWorkDetails () {
      this.sendObj.campaignContactList.push({
        listErr: '',
        isAdd: true,
        contactListMasterId: 0,
        selectListValue: '',
      })
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
  },
};
</script>
<style scoped>
.page-number-input{
    width: 55px;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
}
</style>